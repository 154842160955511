
.mainFeaturedPostFirst {
    position: 'relative';
    color: '#fff';
    padding-top: 100px;    
    padding-bottom: 100px;  
    height: 650px;
    background-image: url(./../img/background.jpg);
}

.mainFeaturedPostFirstLanding {
    position: 'relative';
    color: '#fff';
    background: url(./../img/background_landing.png) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mainFeaturedPostAboutOne {
    position: 'relative';
    color: '#fff';
    background: url(./../img/bg_about.svg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mainFeaturedPostSection5 {
    position: 'relative';
    color: '#fff';
    background: url(./../img/background_sec5.png) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mainFeaturedPostSuccess {
    position: 'relative';
    background: rgb(20,36,97);
    background: linear-gradient(90deg, rgba(20,36,97,1) 0%, rgba(124,113,213,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.overlayFooterBg {
    background: rgb(232,230,245);
    background: linear-gradient(180deg, rgba(232,230,245,1) 0%, rgba(238,230,245,1) 50%, rgba(251,251,251,1) 100%);
}

.paperTestimoniGradient {
    background: rgb(20,36,97);
    background: linear-gradient(22deg, rgba(20,36,97,1) 0%, rgba(124,113,213,1) 94%);
}
.MuiDialogContent-root{
    overflow-y: hidden !important;
}

.react-datepicker-wrapper {
   width: 100%;
}

#menu-list-grow {
    color: '#343c5a';
    margin-top: 16px;
    padding-top: 20px;
    padding-bottom: 50px;
    padding-bottom: 10px;
    width: 200px;
    height: 280px;
    background-color: '#fbfbfb';
}



a:hover {
    color: '#0a449be !important';
}

#fontTamma {
    font-family: 'Baloo Tamma 2';
}

.paperTesti {
    background-color: linear-gradient(120deg, #142461, #7c71d5);
}

.imageBackgroundLeft {
    color: '#fff';
    background: url(./../img/signup.png) no-repeat center center;
}


#navigation-pills {
    text-align: center;
}


#buttonMenuTab {
    font-family: 'Baloo Tamma 2';
}

.makeStyles-pills-95 {
    color : 'blue !important';
}

.MuiToolbar-gutters { 
    padding-left: 0;
    padding-right: 0;
}

@media (min-width: 600px) {
    .MuiToolbar-gutters {
        padding-left: 0;
        padding-right: 0;
    }
}
    
