body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif;
}

a:hover{
  text-decoration: none !important;
}

a {
  text-decoration: none !important;

}

button:focus {
  outline: none !important;
}

/* 
; */
